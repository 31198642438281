<template>
    <section id="card-actions">
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row>
                <b-col cols="8">
                    <b-card no-body class="border-primary">
                        <div class="m-1">
                            <b-row>
                                <b-col cols="4">
                                    <b-form-group label="Buscar articulo por nombre, clave" label-for="search-article">
                                        <b-input-group class="input-group-merge">
                                            <b-form-input :disabled="article.articulo_Id != undefined" @click="activeSearch = true" ref="searchQuote" size="sm"
                                                id="search-article" v-model="article.nombre" autocomplete="off" 
                                                placeholder="Buscar articulo por nombre, clave"/>
                                                <b-input-group-append>
                                                
                                                <b-button @click="activeSearch = true" v-if="!searchQuery && !article.articulo_Id" size="sm" variant="outline-primary">
                                                    <feather-icon icon="SearchIcon" />
                                                </b-button>

                                                <b-button v-else size="sm" @click="resetForm" variant="outline-danger">
                                                    <feather-icon icon="XIcon" />
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>    
                                    
                                </b-col>
                                <b-col cols="8">
                                    <b-button size="sm" @click="$router.push({name: 'apps-inventories-provisional-income'})" variant="primary" class="text-end float-right mr-1 mt-2">
                                        Regresar
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card>
                </b-col>
            </b-row>

            <!-- Tabla items -->
            <b-card no-body>
                <table class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single">
                    <thead>
                        <tr>
                            <th>Clave</th>
                            <th>Articulo</th>
                            <th>UM</th>
                            <th>Existencia</th>
                            <th>Cantidad</th>
                            <th>C. Unit</th>                            
                            <th>Costo Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in articles" :key="index">
                            <td>{{ item.clave_Articulo }}</td>
                            <td>{{ item.nombre }}</td>
                            <td>{{ item.unidad_Compra }}</td>
                            <td>{{ item.existencia }}</td>
                            <td>
                                <b-form-input :ref="'cantidadInput' + index" :id="`article_id-${item.article_Id}`" size="sm" type="number"
                                v-model="item.cantidad" placeholder="0" @input.native="validateItemsToLoad($event, item)"/>
                            </td>
                            <td>
                                <b-form-input :ref="'costoInput' + index" :id="`costo-article_id-${item.article_Id}`" size="sm" type="number"
                                v-model="item.ultimo_Costo" placeholder="0" @input.native="validateItemsToLoad($event, item)"/>
                            </td>

                            <td>{{ item.costo_Total }}</td>
                            
                            
                            <td>
                                <b-button @click="removeItem(index)" size="sm" variant="danger" class="btn-icon">
                                    <feather-icon icon="XIcon" size="16" />
                                </b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <b-card-footer v-if="articles.length > 0">
                    <b-row>
                        <b-col cols="12" md="4">
                            <b-form-group label="Ingresar Comentario (opcional)" :description="`${remainingCharacters} caracteres restantes`" label-for="description">
                                <b-form-textarea 
                                    v-model="limitedObservaciones" id="description" size="sm"
                                    placeholder="Breve comentario de hasta 190 caracteres."
                                    @input="limitText"
                                    maxlength="190"
                                    />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-footer>
            </b-card>

            <div v-if="articles.length > 0" class="buy-now">
                <b-button :disabled="!canSave" @click="store" variant="primary">
                    <feather-icon icon="SaveIcon" size="16" />
                    Guardar
                </b-button>
            </div>
            

            <!-- Componente search articles -->
            <search-articles :active="activeSearch" @selected="selectedArticle"
                @close="activeSearch = false"/>
        </b-overlay>
    </section>
</template>

<script>

import _ from 'lodash'
import {
    BRow, BCol, BCard, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
    BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BListGroup, BListGroupItem, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SearchArticles from '@/@core/components/articles/Search.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import store from '@/store';

import InventoriesProvider from '@/providers/Inventories'
const InventoriesResource = new InventoriesProvider()

export default {
    name: "ProvisionalIncomeAdd",
    components: {
        BRow, BCol, BCard, BCardBody, BCardFooter, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
        BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar, BPagination, BFormSelect,
        BBadge, BCardSubTitle, BCardTitle, BTabs, BTab, BListGroup, BListGroupItem,BTooltip, VBTooltip,

        //
        BCardActions,
        flatPickr,
        vSelect,
        // GlobalArticles,
        SearchArticles,
        FeatherIcon,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    data() {
        return {
            loading: false,
            fields: [
                { key: 'almacen_Id', label: 'ALMACEN ID' },
                { key: 'articulo_Id', label: 'ARTICULO ID' },
                { key: 'articulo', label: 'ARTICULO' },
                { key: 'cantidad', label: 'CANTIDAD' },
                { key: 'unidad_Compra', label: 'U. COMPRA' },
                { key: 'costo_total', label: 'COSTO TOTA' },
                { key: 'existencia', label: 'EXISTENCIA' },
            ],
            searchQuery: null,
            activeSearch: false,
            article: {
                articulo_Id: null,
                nombre: null,
                cantidad: '',
                costo_Total: 0,
            },
            articles: [],
            maxCharacters: 190,
            observaciones: '',
        };
    },
    computed: {
        canSave() {
            // Sumamos todos los costos totales y verificamos si es mayor a 0
            const totalCost = this.articles.reduce((carry, item) => {
                const costoTotal = !isNaN(parseFloat(item.costo_Total)) && item.costo_Total !== null && item.costo_Total !== '' ? parseFloat(item.costo_Total) : 0;
                return carry + costoTotal;
            }, 0);

            // Si el total es mayor a 0, devolvemos true, de lo contrario false
            return totalCost > 0;
        },
        limitedObservaciones: {
            get() {
                return this.observaciones;
            },
            set(value) {
                this.observaciones = value.slice(0, this.maxCharacters);
            }
        },
        remainingCharacters() {
           return this.maxCharacters - this.observaciones.length;
        },
    },
    methods: {
        limitText(event) {
            if (this.observaciones.length > this.maxCharacters) {
                this.observaciones = this.observaciones.slice(0, this.maxCharacters);
            }
        },
        validateItemsToLoad(e, item) { // Event input
            // Verificamos si cantidad y ultimo_Costo son válidos números, de lo contrario los asignamos a 0
            item.cantidad = !isNaN(parseFloat(item.cantidad)) && item.cantidad !== null && item.cantidad !== '' ? parseFloat(item.cantidad) : 0;
            item.ultimo_Costo = !isNaN(parseFloat(item.ultimo_Costo)) && item.ultimo_Costo !== null && item.ultimo_Costo !== '' ? parseFloat(item.ultimo_Costo) : 0;

            // Calculamos el costo total
            item.costo_Total = (item.cantidad * item.ultimo_Costo).toFixed(2);
        },
        async store() {
            let payload = []
            this.articles.forEach(item => {
                payload.push({
                    id: 0,
                    empresa_Id: 0,
                    surcusal_Id: 0,
                    usuario_Id: "string",
                    almacen_Id: 0,
                    almacen: "string",
                    articulo_Id: item.articulo_Id,
                    articulo: item.nombre,
                    clave_Articulo: item.clave_Articulo,
                    um: item.unidad_Compra,
                    unidades: parseFloat(item.cantidad).toFixed(2),
                    costo_Unitario: parseFloat(item.ultimo_Costo).toFixed(2),
                    costo_Total: parseFloat(item.costo_Total).toFixed(2),
                    existencias: item.existencia,
                    descripcion: this.observaciones,
                    estatus: "ACTIVO",
                    fecha: "2024-09-18T01:29:51.146Z",
                    fecha_Alta: "2024-09-18T01:29:51.146Z",
                    fecha_Actualizacion: "2024-09-18T01:29:51.146Z",
                })
            })

            try {
                this.loading = true
                const { data } = await InventoriesResource.addProvisionalIncome(payload)
                this.loading = false
                if (data.isSuccesful) {
                    this.success(data.message)
                    this.resetForm()
                } else {
                    this.danger(data.message)
                }

            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }
        },
        removeItem(index) {
            this.articles.splice(index, 1)
            this.resetForm()
        },
        selectedArticle(evt) {
            
            if (evt && evt.articulo_Id) {
                this.article = {...evt}
                this.articles.push({
                    ...evt,
                    cantidad: '',
                    costo_Total: 0,
                })
            }
        },
        resetForm() {
            this.activeSearch = false
            this.searchQuery = null
            this.articles = []
            this.observaciones = ''
            this.article = {
                articulo_Id: null,
                nombre: null,
                cantidad: '',
            }
        },
        async addInventory() {

            const payload = {
                docto_Id_Id: -1,
                almacen_Id: store.getters['auth/getUser'].almacen_Id,
                concepto_In_Id: 0,
                sucursal_Id: store.getters['auth/getBranch'].sucursal_Id,
                folio: "string",
                naturaleza_Concepto: "string",
                fecha: "2024-08-08T18:58:35.457Z",
                almacen_Destino_Id: 0,
                centro_Costo_Id: 0,
                cancelado: "string",
                aplicado: "string",
                descripcion: "string",
                cuenta_Concepto: "string",
                forma_Emitida: "string",
                contabilizado: "string",
                sistema_Origen: "string",
                usuario_Creador: "string",
                fecha_Hora_Creacion: "2024-08-08T18:58:35.457Z",
                usuario_Aut_Creacion: "string",
                usuario_Ult_Modif: "string",
                fecha_Hora_Ult_Modif: "2024-08-08T18:58:35.457Z",
                usuario_Aut_Modif: "string",
                usuario_Cancelacion: "string",
                fecha_Hora_Cancelacion: "2024-08-08T18:58:35.457Z",
                usuario_Aut_Cancelacion: "string"
            }

            const { data } = await InventoryResource.create(payload)
            console.log(data)
        },
    },
    watch: {
        
    },
    setup() {
        
    },
}
</script>