<template>
    <div>
        <!-- Modal Search Customer -->
        <b-modal id="modal-search-article" ref="modal-search-article" title="Búsqueda de Artículos" @hidden="close"
            @ok="chooseArticle"
            ok-only ok-title="Aceptar" size="lg" no-close-on-backdrop>

            <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
                <div class="m-0">
                    <b-row>
                        <b-col cols="12" md="12">
                            <b-form-radio-group v-model="searchBy" :options="optionSearchs" name="radio-validation" />
                        </b-col>
                    </b-row>
                    <b-row class="mt-2">                        
                        <b-col cols="12" md="8">
                            <div class="d-flex align-items-center justify-content-end">
                                <b-input-group>
                                    <b-form-input size="sm" autofocus v-model="searchQuery" class="d-inline-block _mr-1"
                                        :placeholder="`Buscar por ${searchBy == 'clave_Articulo' ? 'clave' : 'nombre' }`" @keyup.enter="searchArticles" autocomplete="off" />
                                    <b-input-group-prepend>
                                        <b-button size="sm" variant="primary" @click="searchArticles">
                                            <feather-icon icon="SearchIcon" />
                                            Buscar
                                        </b-button>
                                    </b-input-group-prepend>
                                </b-input-group>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                
                <div ref="tableContainer" class="mb-0_ mt-1 table-responsive" :class="articles.length > 0 ? 'b-table-sticky-header' : ''" @scroll="handleScroll">
                    <table class="table b-table table-sm b-table-no-border-collapse b-table-selectable b-table-select-single">
                        <thead>
                            <tr>
                                <th>Clave</th>
                                <th>Articulo</th>
                                <th>U. Compra</th>
                                <th>Últ. Costo</th>
                                <th>Existencia</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in articles" :key="index" @click="rowSelected(item, index)" @dblclick="onRowDoubleClick(item)"
                            :class="{ 'selected-row': isSelected(index) }">
                                <td>{{ item.clave_Articulo }}</td>
                                <td>{{ item.nombre }}</td>
                                <td>{{ item.unidad_Compra }}</td>
                                <td>{{ item.ultimo_Costo }}</td>
                                <td>{{ item.existencia }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </b-overlay>

            <small v-if="articles" class="help-block"><strong>Doble clic en una fila para seleccionar o clic al boton aceptar.</strong></small>
        </b-modal>
    </div>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code'
import {
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay, BSpinner, BTable, BFormCheckbox, BFormGroup, BFormInput,
    BModal, VBModal, BSidebar, BForm, BIcon, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BFormTags, BAvatar,
    BFormRadioGroup,
} from 'bootstrap-vue'
import router from "@/router";
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from "vue-select";
import ArticlesProvider from "@/providers/Articles"
const ArticlesResource = new ArticlesProvider()

export default {
    name: 'SearchArticles',
    directives: {
        Ripple,
        'b-modal': VBModal,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BSidebar,
        BCol,
        BCard,
        BCardBody,
        BCardText,
        BButton,
        BAlert,
        BLink,
        BOverlay,
        BSpinner,
        BTable,
        BTableLite,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BModal,
        VBModal,
        BForm,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BFormTextarea,
        BFormTags,
        BCardCode,
        BAvatar,
        BFormRadioGroup,

        //
        vSelect,
    },
    mixins: [heightTransition],
    props: ["active"],
    data() {
        return {
            loading: false,
            columns: [
                { key: "actions", label: ' ' },
                {
                    key: 'clave_Articulo',
                    label: 'Clave',
                    tdClass: 'text-small',
                },
                {
                    key: "nombre",
                    label: 'Articulo',
                    tdClass: 'text-small',
                },
                {
                    key: "costo",
                    label: "Últ. Costo",
                    tdClass: 'text-small',
                },
                {
                    key: "existencia",
                    label: 'Existencia',
                    tdClass: 'text-small',
                },
            ],
            table: null,
            searchQuery: null,
            selectedRow: null,
            refArticleListTable: null,
            selectableTable: null,
            articles: [],
            currentPage: 1,
            itemsPerPage: 100,
            selectedRowIndex: -1,
            article: {},
            visibleItems: [],
            endOfData: false,
            optionSearchs: [
                { text: 'Por Clave', value: 'clave' },
                { text: 'Por Nombre', value: 'nombre' },
            ],
            searchBy: 'clave',
        }
    },
    created() {
        
    },
    beforeDestroy() {
        // const tableContainer = this.$refs.tableContainer;
        // tableContainer.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
       
    },
    methods: {
        loadItems() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = this.currentPage * this.itemsPerPage;
            this.visibleItems = this.articles.slice(0, end);
        },      
        handleScroll() {
            const tableContainer = this.$refs.tableContainer;
            const bottomOfTable = tableContainer.scrollTop + tableContainer.clientHeight >= tableContainer.scrollHeight - 10;
            if (bottomOfTable && !this.loading) {
                this.currentPage++;
                this.loadItems();
            }
        },
        onRowDoubleClick(item) {
            this.$emit("selected", { ...item });
            this.close();
        },
        chooseArticle() {
            this.$emit("selected", { ...this.article });
            this.close();
        },
        async searchArticles() {
            this.loading = true;
            this.articles = []
            const query = {
                search: this.searchQuery,
                pageSize: 300,
                pageNumber: 1,
                esAlmacenable: true,
                buscarPor: this.searchBy,
            }

            try {                
                const { data } = await ArticlesResource.searchArticles(query)                
                this.loading = false   
                if (data.isSuccesful) {             
                    this.articles = data.data
                } else {
                    this.danger(data.message)
                }
            }catch(e) {
                this.loading = false
                this.handleResponseErrors(e)
            }finally{
                this.loading = false
            }
		},
        rowSelected(item, index) {
            this.article = {}
            this.article = item
            this.selectedRowIndex = index           
        },       
        close() {
            this.$refs['modal-search-article'].hide()
            this.article = {}
            this.$emit("close");
        },
        isSelected(index) {
            return index === this.selectedRowIndex;
        },
    },
    watch: {
        searchBy(value) {
            this.searchQuery = ''
            this.articles = []
        },
        searchQuery(value) {
            this.selectedRowIndex = -1
            this.articles = []
            this.article = {}
            if (value) {
                // this.getCharges(value.cliente_Id)
            }
        },
        active(value) {
            if (value) {
                this.articles = []
                this.article = {}
                this.searchQuery = null                
                this.$refs['modal-search-article'].toggle()
            } else {
                this.close()
            }
        }
    }
}
</script>

<style>
.selected-row {
    background-color: #007bff; /* Color de fondo para la fila seleccionada */
    color: white; /* Color de texto para la fila seleccionada */
}

.b-table-sticky-header {
    overflow-y: auto;
    min-height: 300px;
}

</style>